import * as React from "react";
import {graphql} from 'gatsby';
import Seo from '../../components/Seo';
import Layout from '../../components/layout/Layout';
import PortfolioHero from '../../components/PortfolioHero';
import PortfolioLeft from '../../components/PortfolioLeft';
import PortfolioRight from '../../components/PortfolioRight';
import SectionStatistics from "../../components/SectionStatistics";
import SectionCTA from '../../components/SectionCTA';
import imgHero from '../../images/portfolio/mixercms/mixercms4.png'
import imgRight1 from '../../images/portfolio/mixercms/mixercms9.png'
import imgLeft1 from '../../images/portfolio/mixercms/mixercms8.png'
import imgRight2 from '../../images/portfolio/mixercms/mixercms6.png'
import imgLeft2 from '../../images/portfolio/mixercms/mixercms1.png'
import imgRight3 from '../../images/portfolio/mixercms/mixercms3.png'
import imgLeft3 from '../../images/portfolio/mixercms/mixercms5.png'
import imgRight4 from '../../images/portfolio/mixercms/mixercms7.png'
import imgLeft4 from '../../images/portfolio/mixercms/mixercms2.png'

// markup
const PortfolioMixerCMS = () => {
  return (
    <Layout>
      <Seo />
      <PortfolioHero 
        img={imgHero}
        appname="MIXER CMS"
        appcat="Chuyển đổi số Ngành xây dựng"
        title="Giải Pháp Quản Lý Đa Trạm Trộn Bê Tông"
        desc="Quản lý tập trung thông tin Đa trạm trên một ứng dụng. Theo dõi trạng thái hoạt động các trạm. Đồng bộ hóa dữ liệu giữa các trạm. Giảm thiểu các tác vụ thủ công. Trích xuất báo cáo cho tất cả các trạm." 
      />
      <PortfolioRight 
        img={imgRight2}
        title="Đồng Bộ Thông Tin Các Trạm"
        desc="Xây dựng trên nền tảng Tự động hóa Nghiệp vụ IOHUB, ứng dụng hỗ trợ đồng bộ hóa thông tin giữa các trạm với nhau giúp dễ dàng quản lý nghiệp vụ trộn bê tông. Nếu trước đây phải nhập thông tin trên từng trạm thì bây giờ chỉ cần nhập 1 lần để quản lý tất cả các trạm trộn." 
      />
      <PortfolioLeft 
        img={imgLeft1}
        title="Quản Lý Lệnh Trộn"
        desc="Chuyển giữa các trạm nhanh gọn để quản lý lệnh trộn. Luôn kiểm soát được tình trạng các đơn hàng đang trộn, điều xe trộn và tài xế, điều chỉnh mác bê tông dễ dàng." 
      />
      <PortfolioRight 
        img={imgRight1}
        title="Quản Lý Phiếu Xuất Bê Tông"
        desc="Theo dõi trạng thái từng mẻ trộn và in phiếu trộn với đầy đủ thông tin đơn hàng, cấp phối bê tông." 
      />
      <PortfolioLeft 
        img={imgLeft2}
        title="Quản Lý Đơn Hàng"
        desc="Quản lý tập trung tất cả đơn hàng. Tìm thông tin đơn hàng, khách hàng, công trình dễ dàng qua các bộ lọc đa tiêu chí." 
      />
      <PortfolioRight 
        img={imgRight3}
        title="Quản Lý Đơn Hàng Nhiều Mác Bê Tông"
        desc="Tạo 1 đơn hàng cho tất cả các trạm, gắn mác bê tông khác nhau trên từng trạm cho trường hợp trộn nhiều mác trên 1 đơn hàng. Lệnh trộn khi tạo trên từng trạm sẽ tương ứng mác đã chọn trong đơn hàng." 
      />
      <PortfolioLeft 
        img={imgLeft3}
        title="Hỗ Trợ Đa Thiết Bị"
        desc="Ứng dụng vận hành tốt trên nhiều thiết bị như smartphone, iPad, tablet, máy PC. Bạn không bị bó buộc vào máy PC để thực hiện tác vụ của mình." 
      />
      <PortfolioRight 
        img={imgRight4}
        title="Thông Tin Đa Dạng"
        desc="Thông tin cấp phối bê tông đầy đủ giúp bạn luôn nắm được chính xác nguyên liệu và khối lượng của từng mẻ trộn." 
      />
      <PortfolioLeft 
        img={imgLeft4}
        title="Báo Cáo Thông Tin Đa Trạm"
        desc="Báo cáo đa dạng giúp bạn luôn nắm bắt tình hình vận hành của tất cả các trạm." 
      />
      <SectionStatistics />
      <SectionCTA />
    </Layout>
  )
};

export default PortfolioMixerCMS

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "mixercms"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;