import React from 'react';

const PortfolioHero = ({ img, appname, appcat, title, desc }) => {
  return(
    <section className="text-gray-600 body-font pt-5 px-5">
      <div className="container mx-auto flex px-5 py-10 items-center justify-center flex-col">
        <div className="text-center lg:w-2/3 w-full">
          <p className="leading-relaxed uppercase">{appcat}</p>
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-900">{appname}</h1>
        </div>
        <img src={img} className="w-5/6 lg:w-1/2 mb-10 object-cover object-center rounded" alt="" />
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-900">{title}</h1>
          <p className="mb-8 leading-relaxed text-2xl">{desc}</p>
        </div>
      </div>
    </section>
  );
};

export default PortfolioHero