import React from 'react';

const PortfolioLeft = ({ img, title, desc }) => {
  return(
    <section className="text-gray-600 body-font px-5">
      <div className="container mx-auto flex px-5 py-10 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-blue-900">{title}</h1>
          <p className="mb-8 leading-relaxed text-xl">{desc}</p>
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img src={img} className="object-cover object-center rounded" alt="" />
        </div>
      </div>
    </section>
  );
};

export default PortfolioLeft